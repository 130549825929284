import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CovidStateMetrics from "../containers/CovidStateMetrics";
import EmptyApp from "../containers/EmptyApp";
import "../App.css";
import 'antd/dist/antd.css'

export default function AppRouting(props) {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={EmptyApp} />
        <Route path="/covid19" component={CovidStateMetrics} />
        {/* <Route path="/demo" component={About} > */}
      </Switch>
    </Router>
  );
}
