import ReactEcharts from 'echarts-for-react';
import { connect } from "react-redux";
import lodash from 'lodash'
import React, { useState, useEffect } from 'react';
import fetch from 'node-fetch'
import {
    Card,
    Select,
    Alert,
    Layout,
    Typography,
    Row,
    Col,
} from 'antd';

const { Content, Footer } = Layout;
const { Title } = Typography;
const { Option } = Select;

const NUM_COUNTIES = 30;

const gridStyle = {
    width: '50%',
    textAlign: 'center',
};

function CovidStateMetrics({ state }) {

    const [data, setData] = useState([]);
    const [total, setTotal] = useState({});
    const [usTotal, setUSTotal] = useState({});
    const [date, setDate] = useState('');
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(state)
    const [showPicker, setShowPicker] = useState(false)

    useEffect(() => {
        async function fetchData() {
            let res = await fetch(
                'https://us-central1-njtbustracker.cloudfunctions.net/app/v1/covid/query',
                { method: 'POST', body: `select * from counties where state = '${selectedState}'` })
            setData(await res.json());

            res = await fetch(
                'https://us-central1-njtbustracker.cloudfunctions.net/app/v1/covid/query',
                { method: 'POST', body: `select max(date) as date from counties where state = '${selectedState}'` })
            const date = (await res.json())[0].date;
            setDate(date);

            res = await fetch(
                'https://us-central1-njtbustracker.cloudfunctions.net/app/v1/covid/query',
                {
                    method: 'POST', body: `
                    select sum(cases) as cases, sum(deaths) as deaths 
                    from counties 
                    where date = '${date}'`
                })
            setUSTotal((await res.json())[0])

            res = await fetch(
                'https://us-central1-njtbustracker.cloudfunctions.net/app/v1/covid/query',
                {
                    method: 'POST', body: `
                    select sum(cases) as cases, sum(deaths) as deaths 
                    from counties 
                    where state = '${selectedState}' and date = '${date}'`
                })
            setTotal((await res.json())[0]);

            res = await fetch(
                'https://us-central1-njtbustracker.cloudfunctions.net/app/v1/covid/query',
                {
                    method: 'POST', body: `
                    select distinct(state) as state 
                    from counties 
                    where date = '${date}' 
                    order by state`})
            setStates((await res.json()).map(each => each.state));
        }
        fetchData();
    }, [selectedState]);

    const filteredData = data.map(each => {
        return {
            ...each,
            date: each.date.substring(5),
        }
    })


    const dataByCounty = lodash.chain(filteredData)
        .groupBy("county")
        .map((value, key) => ({ county: key, dataPoints: value, rank: value[value.length - 1].cases }))
        .filter(each => each.county !== 'Unknown')
        .orderBy(['rank'], ['desc'])
        .take(NUM_COUNTIES)
        .value()

    const usSummaryCard = (
        <Card title={`United States (as of ${date})`} style={{margin: '5px 0'}}>
            <Card.Grid style={gridStyle}>
                <font color="blue">{usTotal.cases}</font> confirmed 
            </Card.Grid>
            <Card.Grid style={gridStyle}>
                <font color="red">{usTotal.deaths}</font> {`deaths (${Math.floor((usTotal.deaths / usTotal.cases) * 10000) / 100}%)`}
            </Card.Grid>
        </Card>
    )

    const stateSummaryCard = (
        <Card title={`${selectedState} (as of ${date})`} style={{margin: '5px 0'}}>
            <Card.Grid style={gridStyle}>
                <font color="blue">{total.cases}</font> confirmed 
            </Card.Grid>
            <Card.Grid style={gridStyle}>
                <font color="red">{total.deaths}</font> {`deaths (${Math.floor((total.deaths / total.cases) * 10000) / 100}%)`}
            </Card.Grid>
        </Card>
    )

    let view = (
        <Layout>
            <Content style={{ padding: '5px 10px' }}>
                <Row>
                    <Col span={17}>
                        <Title level={2}> Coronavirus Case Data for U.S. County </Title>
                    </Col>
                    <Col span={7}>
                        <Select defaultValue={selectedState} style={{ width: 120 }} onChange={(e) => setSelectedState(e)}>
                            {states.map((each, i) => <Option key={i} value={each}>{each}</Option>)}
                        </Select>
                    </Col>
                </Row>

                {usSummaryCard}

                <Title level={3} style={{margin: '5px 0'}}>
                    {selectedState}
                </Title>

                {stateSummaryCard}

                {
                    dataByCounty.map((each, i) => {
                        const eachData = each.dataPoints;
                        const totalCases = eachData[eachData.length - 1].cases;
                        const totalDeaths = eachData[eachData.length - 1].deaths;

                        return (
                            <Card key={i} title={each.county} extra={`${totalCases} confirmed ${totalDeaths} deaths`} style={{ margin: '15px 0px' }}>
                                <ReactEcharts key={i} option={getOption(eachData, each.county)} />
                            </Card>
                        );
                    })
                }
            </Content>
            <Footer>{`We only show up to top ${NUM_COUNTIES} counties with the most confirmed cases for each state.`}</Footer>
        </Layout>
    )

    if (!total.cases || date === '' || states.length === 0) {
        view = null;
    }

    return view;
}

const getOption = (data, title) => {
    const legends = ['Cases', 'Deaths']

    const xAxisData = data.map(each => each.date);

    const series = [
        {
            name: 'Cases',
            type: 'line',
            data: data.map(each => parseInt(each.cases))
        },
        {
            name: 'Deaths',
            type: 'line',
            data: data.map(each => parseInt(each.deaths))
        },
    ]

    return {
        title: {
            text: 'Trend'
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: legends
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData
        },
        yAxis: {
            type: 'value'
        },
        series,
    };
}

const mapStateToProps = state => ({
    state: state.router.location.query.state ? decodeURI(state.router.location.query.state) : 'New York'
});

export default connect(mapStateToProps)(CovidStateMetrics);