// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import configStore, { history } from "./configStore";
import { Provider } from "react-redux";
// Google font for material-ui
import "typeface-roboto";
import "./index.css";
import 'mapbox-gl/dist/mapbox-gl.css';

import * as serviceWorker from "./serviceWorker";
import AppRouting from "./components/AppRouting";

// https://console.firebase.google.com/u/0/project/njtbustracker/settings/general/web:NjQxZjRmNDgtYjU4OS00MTVmLThhZjQtMmUyNGQ4OTFmY2Fh
const firebaseConfig = {
  apiKey: "AIzaSyAq6j1V3PzhW6m0X2h8Ca76CIygOeQj-zY",
  authDomain: "njtbustracker.firebaseapp.com",
  databaseURL: "https://njtbustracker.firebaseio.com",
  projectId: "njtbustracker",
  storageBucket: "njtbustracker.appspot.com",
  messagingSenderId: "714307190674",
  appId: "1:714307190674:web:d321beed60d09f86c9cb4b",
  measurementId: "G-Y3FXJZ4TJW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// DON't FORGET THIS LINE. Otherwise, Analytics won't work.
firebase.analytics();

const store = configStore({});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppRouting />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
